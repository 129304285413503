import axios, { AxiosRequestConfig } from 'axios';
import {
    APILoginResponse,
    APILogout,
    APIGuildListResponse,
    APIGuildDetailsResponse,
    APIETAResponse,
    APIPopulationResponse,
    APICharacterDataResponse,
    APIDashboardDataResponse,
} from '../types';
import { State } from './state';

export const API = (() => {
    const getSession = (state: State): string => {
        if (state !== null && state.User !== null) {
            return state.User.Token
        }
        return "";
    }

    const getRequestConfig = (state: State, isPost: boolean = false): AxiosRequestConfig => {
        let config: AxiosRequestConfig = {}
        let headers: { [k: string]: any } = {};

        const session = getSession(state)
        if (session !== "") {
            headers["Authorization"] = `Bearer ${session}`
        }

        if (isPost) {
            headers["Content-Type"] = "application/json"
        }
        config.headers = headers
        return config
    }

    const parseURL = (url: string): string => {
        if (url.indexOf("/") !== 0) {
            url = `/${url}`
        }
        return `${API_HOST}${url}`
    }

    async function POST<Type = any>(state: State, url: string, data: string): Promise<Type> {
        url = parseURL(url)
        let result = await axios.post<Type>(url, data, getRequestConfig(state, true))
        return result.data
    }

    async function GET<Type = any>(state: State, url: string): Promise<Type> {
        url = parseURL(url)
        let result = await axios.get<Type>(url, getRequestConfig(state, true))
        return result.data
    }

    const API_HOST = process.env.REACT_APP_API_HOST

    return {
        async logout(state: State): Promise<boolean> {
            let token = getSession(state);
            if (token !== "") {
                let payload = { Token: token }
                POST<APILogout>(state, "/logout", JSON.stringify(payload))
            }
            return true
        },
        async finalizeLogin(state: State, code: string, oauthState: string): Promise<APILoginResponse> {
            return await GET<APILoginResponse>(state, `/login-finalize?code=${code}&state=${oauthState}`)
        },
        async loadGuilds(state: State): Promise<APIGuildListResponse> {
            return await GET<APIGuildListResponse>(state, `/v2/guilds`)
        },
        async loadGuildDetails(state: State, guildID: string): Promise<APIGuildDetailsResponse> {
            return await GET<APIGuildDetailsResponse>(state, `/v2/guilds/by-id/${guildID}`)
        },
        async loadETAs(state: State, path: string): Promise<APIETAResponse> {
            return await GET<APIETAResponse>(state, `/v2/public/etas${path}`)
        },
        async loadClassPopulation(state: State, level: number): Promise<APIPopulationResponse> {
            return await GET<APIPopulationResponse>(state, `/v1/public/class-population/${level}`)
        },
        async loadServerPopulation(state: State, level: number): Promise<APIPopulationResponse> {
            return await GET<APIPopulationResponse>(state, `/v1/public/server-population/${level}`)
        },
        async loadCharacterData(state: State, region: string, name: string) {
            return await GET<APICharacterDataResponse>(state, `/v2/public/character/${region}/${name}`)
        },
        async loadDashboardData(state: State) {
            return await GET<APIDashboardDataResponse>(state, `/v2/dashboard`)
        }
    }
})()