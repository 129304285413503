import { FC, SyntheticEvent } from 'react';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import lucidImg from '../images/lucid_header.png';
import lucidImgWebp from '../images/lucid_header.webp';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerDiv: {
            padding: "2rem",
            boxSizing: 'content-box',
            textAlign: 'center',
        },
        headerImg: {
            width: "30%",
            padding: 20,
        },
        headerLink: {
            textDecoration: "none",
        }
    }),
);

const sxElements = {
    headerText: {
        color: "#EEF",
        fontSize: "2em",
    },
};

const SidebarHeader: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.headerDiv}>
            <RouterLink className={classes.headerLink} to="/">
                <img alt="Lucid Header" src={lucidImgWebp} className={classes.headerImg} height={85} width={85} onError={(evt: SyntheticEvent<HTMLImageElement>) => { evt.currentTarget.onerror = null; evt.currentTarget.src = lucidImg }} />
                <Typography variant="h2" sx={sxElements.headerText}>MapleStory.gg</Typography>
            </RouterLink>
        </div>
    );
};

export default SidebarHeader;