import { FC, Fragment, MouseEvent as ReactMouseEvent, SyntheticEvent, FormEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/lab/Alert';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { withStyles, makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { SxProps } from '@mui/system';

interface OptionType {
    label: string;
    Name: string;
    Region: number;
}

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        margin: 0,
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        padding: '8px 32px 8px 6px !important',
        fontSize: "0.9rem",
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: "Montserrat, Verdana, Sans-Serif",
        '&:focus': {
            borderRadius: 4,
            borderColor: 'none',
            boxShadow: 'none',
            fontFamily: "Montserrat, Verdana, Sans-Serif",
        }
    },
}))(InputBase);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        CharacterSearchForm_form: {
            width: "100%",
            display: "flex",
        },
        CharacterSearchForm_root: {
            padding: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            textAlign: "center",
        },
        autocompleteContainer: {
            flexGrow: 1,
            position: "relative",
        },
        autocompleteSuggestionsContainerOpen: {
            position: "absolute",
            zIndex: 9001,
            background: "#FFF",
            width: "calc(100% + 140px)",
            top: 42,
            left: -140,
            right: 0,
            [theme.breakpoints.down('sm')]: {
                left: -90,
                width: "calc(100% + 90px)",
            }
        },
        autocompleteSuggestionsContainerOpenSidebar: {
            position: "absolute",
            zIndex: 9001,
            background: "#FFF",
            top: 42,
            right: 0,
            left: -85,
            width: "calc(100% + 85px)",
        },
        autocompleteSuggestionsList: {
            margin: 0,
            padding: 0,
            listStyleType: "none",
        },
        autocompleteSuggestion: {

        },
        CharacterSearchForm_header_input: {

        },
        CharacterSearchForm_input: {
            padding: "2px 4px !important",
            marginLeft: "8px !important",
            flex: 1,
            flexGrow: 1,
            fontFamily: `"Montserrat, Verdana, Sans-Serif" !important`,
            position: "relative",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0) !important",
        },
        CharacterSearchForm_input_field: {
            font: `400 16px "Montserrat" !important`,
            padding: "2px 4px !important",
        },
        autocompleteItem: {
            fontFamily: "Montserrat, Verdana, Sans-Serif !important",
            fontSize: "1rem",
        },
        autocompleteChip: {
            fontSize: "1rem",
            marginLeft: 19,
            marginRight: 54,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                marginRight: 23,
            }
        },
        autocompleteChipSidebar: {
            fontSize: "1rem",
            marginLeft: 0,
            marginRight: 18,
        }
    })
);

interface CharacterSearchFormSXElements {
    CharacterSearchForm_button: SxProps<Theme>;
    CharacterSearchForm_divider: SxProps<Theme>;
}

const SXElements: CharacterSearchFormSXElements = {
    CharacterSearchForm_button: {
        fontFamily: "Montserrat, Verdana, Sans-Serif",
        color: "rgba(0, 0, 0, 0.87)",
        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.2)",
        }
    },
    CharacterSearchForm_divider: {
        width: "1px",
        height: "28px",
        margin: "4px",
        zIndex: 1,
        backgroundColor: "rgba(0,0,0,0.15)",
    },
}

const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface CharacterSearchFormProps {
    isSidebar: boolean;
}

const CharacterSearchForm: FC<CharacterSearchFormProps> = (props: CharacterSearchFormProps) => {
    const classes = useStyles();
    const [region, setRegion] = useState(1);
    const [suggestions, setSuggestions] = useState<OptionType[]>([]);
    const [characterName, setCharacterName] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const history = useHistory();

    const handleClose = (event?: SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowError(false);
    };

    const searchAPI = (name: string, regionStr: string): Promise<Response> => {
        return fetch(`${process.env.REACT_APP_API_HOST}/v1/public/autocomplete/${encodeURIComponent(name)}/${encodeURIComponent(regionStr)}`);
    };
    const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 500);

    const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        if (characterName !== "") {
            let regionStr = "gms";

            if (region === 2) {
                regionStr = "ems";
            }

            let charPath: string = `/c/${regionStr.toLowerCase()}/${characterName}`
            history.push(charPath);
        } else {
            setShowError(true)
        }
    }
    const handleChange = (evt: SelectChangeEvent) => {
        let val = evt.target.value as string;
        let rgn = parseInt(val);
        setRegion(rgn);
    }
    const renderInputComponent = (inputProps: any) => {
        const { inputRef = () => { }, ref, ...other } = inputProps;

        if (props.isSidebar) {
            return (
                <InputBase
                    {...other}
                    name="characterName"
                    className={classes.CharacterSearchForm_input}
                    placeholder="Name"
                    inputProps={{ 'aria-label': 'Name', 'maxLength': 12, 'autoComplete': 'off' }}
                    inputRef={node => {
                        ref(node);
                        inputRef(node);
                    }}
                    classes={{
                        input: classes.CharacterSearchForm_input_field,
                    }}
                />
            );
        }

        return (
            <InputBase
                {...other}
                name="characterName"
                className={classes.CharacterSearchForm_input}
                placeholder="Character Name"
                inputProps={{ 'aria-label': 'Character Name', 'maxLength': 12, 'autoComplete': 'off' }}
                inputRef={node => {
                    ref(node);
                    inputRef(node);
                }}
                classes={{
                    input: classes.CharacterSearchForm_input_field,
                }}
                autoFocus="autofocus"
            />
        );

    }

    const handleSuggestionsFetchRequested = ({ value }: { value: string }) => {
        if (value !== "" && value.length >= 3) {
            setSuggestions([])

            let regionStr = "gms";
            switch (region) {
                case 1:
                    regionStr = "gms";
                    break;
                case 2:
                    regionStr = "ems";
                    break;
                // WORK IN PROGRESS
                // case 3:
                //     regionStr = "kms";
                //     break;
                // case 4:
                //     regionStr = "jms";
                //     break;
                default:
                    regionStr = "gms";
                    break;
            }

            searchAPIDebounced(value, regionStr).then(function (response) {
                return response.json();
            }).then((data) => {
                if (Array.isArray(data)) {
                    setSuggestions(data);
                } else {
                    setSuggestions([]);
                }
            });
        }
    }

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    }
    const getSuggestionValue = (suggestion: OptionType) => {
        return suggestion.Name;
    }
    const onSuggestionSelected = (evt: FormEvent<any>, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }: Autosuggest.SuggestionSelectedEventData<OptionType>) => {
        evt.preventDefault();

        setRegion(suggestion.Region)
        setCharacterName(suggestionValue)
    }
    const renderSuggestion = (suggestion: OptionType, { query, isHighlighted }: Autosuggest.RenderSuggestionParams) => {
        const matches = match(suggestion.Name, query);
        const parts = parse(suggestion.Name, matches);
        // const pathname = this.props.location;

        var regionString = "GMS";
        if (suggestion.Region === 2) {
            regionString = "EMS";
        }

        if (suggestion && !props.isSidebar) {
            return (
                <MenuItem selected={isHighlighted} component="div" className={classes.autocompleteItem}>
                    <div>
                        <Chip className={classes.autocompleteChip} label={regionString} variant="outlined" />
                        {parts.map(part => (
                            <span key={part.text} style={{ fontWeight: part.highlight ? 'bold' : 400 }}>
                                {part.text}
                            </span>
                        ))}
                    </div>
                </MenuItem>
            );
        } else if (suggestion && props.isSidebar) {
            return (
                <MenuItem selected={isHighlighted} component="div" className={classes.autocompleteItem}>
                    <div>
                        <Chip className={classes.autocompleteChipSidebar} label={regionString} variant="outlined" />
                        {parts.map(part => (
                            <span key={part.text} style={{ fontWeight: part.highlight ? 'bold' : 400 }}>
                                {part.text}
                            </span>
                        ))}
                    </div>
                </MenuItem>
            );
        }

        return (
            <Fragment>
            </Fragment>
        )
    }
    // const handleTextChange = (newValue: string) => {
    const handleTextChange = (event: FormEvent<HTMLElement>, { newValue, method }: Autosuggest.ChangeEvent) => {
        setCharacterName(newValue);
    }
    const preventDefault = (evt: ReactMouseEvent<HTMLElement, MouseEvent>) => {
        evt.stopPropagation();
    }

    const Options = (): JSX.Element => {
        if (props.isSidebar) {
            return (<Fragment>
                <option value={1}>GMS</option>
                <option value={2}>EMS</option>
                <option disabled value={3}>KMS</option>
                <option disabled value={4}>JMS</option>
            </Fragment>);
        }

        return (<Fragment>
            <option value={1}>Global (GMS)</option>
            <option value={2}>Europe (EMS)</option>
            <option disabled value={3}>Korea (KMS)</option>
            <option disabled value={4}>Japan (JMS)</option>
        </Fragment>);
    }

    const SubmitButton = (): JSX.Element => {
        if (props.isSidebar) {
            return (
                <Button type="submit" sx={SXElements.CharacterSearchForm_button}><FontAwesomeIcon icon={faSearch} /></Button>
            )
        }

        return (
            <Button type="submit" sx={SXElements.CharacterSearchForm_button}>Search</Button>
        )
    }

    const OptionWidth = (): number => {
        if (props.isSidebar) {
            return 120
        }

        return 145
    }

    const SuggestionsContainer = (): string => {
        if (props.isSidebar) {
            return classes.autocompleteSuggestionsContainerOpenSidebar
        }

        return classes.autocompleteSuggestionsContainerOpen
    }

    return (
        <Fragment>
            <form method="POST" className={classes.CharacterSearchForm_form} onSubmit={handleSubmit}>
                <Grid container justifyContent="center">
                    <Paper className={classes.CharacterSearchForm_root}>
                        <Hidden smDown>
                            <Select
                                name="regionSelect"
                                native
                                value={region.toString(10)}
                                onChange={handleChange}
                                input={<BootstrapInput name="Region" id="Region-customized-select" />}
                                sx={{ width: OptionWidth(), backgroundColor: "rgba(0,0,0,0) !important", }}
                            >
                                {Options()}
                            </Select>
                        </Hidden>
                        <Hidden smUp>
                            <Select
                                name="regionSelect"
                                native
                                value={region.toString(10)}
                                onChange={handleChange}
                                input={<BootstrapInput name="Region" id="Region-customized-select" />}
                                sx={{ width: 90, backgroundColor: "rgba(0,0,0,0) !important", }}
                            >
                                <option value={1}>GMS</option>
                                <option value={2}>EMS</option>
                                <option disabled value={3}>KMS</option>
                                <option disabled value={4}>JMS</option>
                            </Select>
                        </Hidden>
                        <Divider sx={SXElements.CharacterSearchForm_divider} />

                        <Autosuggest
                            renderInputComponent={renderInputComponent}
                            renderSuggestion={renderSuggestion}
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
                            onSuggestionsClearRequested={handleSuggestionsClearRequested}
                            getSuggestionValue={getSuggestionValue}
                            onSuggestionSelected={onSuggestionSelected}
                            inputProps={{
                                name: "characterName",
                                value: characterName,
                                onChange: handleTextChange,
                                onClick: preventDefault
                            }}
                            theme={{
                                container: classes.autocompleteContainer,
                                suggestionsContainerOpen: SuggestionsContainer(),
                                suggestionsList: classes.autocompleteSuggestionsList,
                                suggestion: classes.autocompleteSuggestion,
                            }}
                        />

                        <Divider sx={SXElements.CharacterSearchForm_divider} />
                        {SubmitButton()}
                    </Paper>
                </Grid>
            </form>
            <Snackbar open={showError} autoHideDuration={4000} onClose={handleClose}>
                <Alert severity="error" onClose={handleClose}>
                    Character Name cannot be empty
                </Alert>
            </Snackbar>
        </Fragment>
    )
}

export default CharacterSearchForm;