import { ComponentType, FC } from 'react';
import { Redirect, Route, RouteComponentProps, withRouter } from 'react-router';
import { State } from '../overmind/state';

export interface AuthRouteProps extends RouteComponentProps {
    component?: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
    // children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
    path?: string | string[];
    exact?: boolean;
    sensitive?: boolean;
    strict?: boolean;
    session: State;
}

const AuthorizedRoute: FC<AuthRouteProps> = props => {
    let redirectPath = '';
    if (!props.session.LoggedIn) {
        redirectPath = "/login";
    }

    if (redirectPath) {
        let returnPath = ""
        if (props.location?.pathname !== undefined) {
            returnPath = `?r=${props.location?.pathname}`;
        }
        const renderComponent = () => <Redirect to={{ pathname: redirectPath, search: returnPath }} />;
        return <Route {...props} component={renderComponent} render={undefined} />;
    } else {
        return <Route {...props} />;
    }
};

export default withRouter(AuthorizedRoute);
