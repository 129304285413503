import './wdyr';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import App from './App';

import { createOvermind } from 'overmind';
import { Provider } from 'overmind-react';
import { config } from './overmind';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

import * as serviceWorker from './serviceWorker';
import './index.css';

const overmind = createOvermind(config)

render(
    <StrictMode>
        <Provider value={overmind}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <App />
            </ThemeProvider>
        </Provider>
    </StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();
