import { FunctionComponent, lazy } from 'react';
// import HomePage from '../pages/HomePage';
// // import RankingsPage from '../pages/RankingsPage';
// import DashboardPage from '../pages/DashboardPage';
// import SchedulePage from '../pages/SchedulePage';
// import GuildsPage from '../pages/GuildsPage';
// import CharacterPage from '../pages/CharacterPage';
// import GuildDetailPage from '../pages/GuildDetailPage';
// import NoPageFound from '../pages/NoPageFound';
// import StatisticsPage from '../pages/StatisticsPage';
// import ETAPage from '../pages/ETAPage';

const HomePage = lazy(() => import('../pages/HomePage'));
const DashboardPage = lazy(() => import('../pages/DashboardPage'));
const SchedulePage = lazy(() => import('../pages/SchedulePage'));
const GuildsPage = lazy(() => import('../pages/GuildsPage'));
const CharacterPage = lazy(() => import('../pages/CharacterPage'));
const GuildDetailPage = lazy(() => import('../pages/GuildDetailPage'));
const NoPageFound = lazy(() => import('../pages/NoPageFound'));
const StatisticsPage = lazy(() => import('../pages/StatisticsPage'));
const ETAPage = lazy(() => import('../pages/ETAPage'));
// const ToolsPage = lazy(() => import('../pages/ToolsPage'));

type Route = {
    Title: string,
    Path: string,
    RequiresAuth: boolean,
    Component?: FunctionComponent,
    Exact?: boolean,
    DisplayInNav?: boolean,
}

export const routes: Route[] = [
    {
        Title: "Home",
        Path: "/",
        RequiresAuth: false,
        Component: HomePage,
        Exact: true,
    },
    // {
    //     Title: "Rankings",
    //     Path: "/rankings",
    //     RequiresAuth: false,
    //     Component: RankingsPage,
    // },
    {
        Title: "ETAs",
        Path: "/etas",
        RequiresAuth: false,
        Component: ETAPage,
    },
    {
        Title: "Statistics",
        Path: "/statistics",
        RequiresAuth: false,
        Component: StatisticsPage,
    },
    // {
    //     Title: "Tools",
    //     Path: "/tools",
    //     RequiresAuth: false,
    //     Component: ToolsPage,
    // },
    {
        Title: "Character",
        Path: "/c/:region/:name",
        RequiresAuth: false,
        Component: CharacterPage,
        DisplayInNav: false,
    }
    // {
    //     Title: "Information",
    //     Path: "/info",
    //     RequiresAuth: false,
    // },
];

export const authRoutes: Route[] = [
    {
        Title: "Dashboard",
        Path: "/dashboard",
        RequiresAuth: true,
        Component: DashboardPage,
    },
    {
        Title: "My Guilds",
        Path: "/guilds",
        RequiresAuth: true,
        Component: GuildsPage,
    },
    {
        Title: "My Schedule",
        Path: "/schedule",
        RequiresAuth: true,
        Component: SchedulePage,
    },
    {
        Title: "Legion Management",
        Path: "/legion",
        RequiresAuth: true,
        Component: NoPageFound,
    },
    {
        Title: "Developer Portal",
        Path: "/developers",
        RequiresAuth: true,
        Component: NoPageFound,
    },
    {
        Title: "Guild Details",
        Path: "/guild/:id",
        RequiresAuth: true,
        Component: GuildDetailPage,
        DisplayInNav: false,
    }
]

export default routes;