import { FC, Suspense, lazy, useState } from 'react';
import MobileNavigation from './components/MobileNavigation';
import SidebarNavigation from './components/SidebarNavigation';
import AuthorizedRoute from './components/AuthorizedRoute';
import LoadingPage from './pages/LoadingPage';

// import NoPageFound from './pages/NoPageFound';
// import LoginFinalizePage from './pages/LoginFinalizePage';
// import LoginFailedPage from './pages/LoginFailedPage';
// import LogoutPage from './pages/LogoutPage';
// import ForbiddenPage from './pages/ForbiddenPage';
// import ServerErrorPage from './pages/ServerErrorPage';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { routes, authRoutes } from './routes';
import './overmind/state';

import Hidden from '@mui/material/Hidden';
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles';
import { useAppState } from './overmind';
import classnames from 'classnames';
// import LoadingOverlay from './components/LoadingOverlay';

const NoPageFound = lazy(() => import('./pages/NoPageFound'));
const LoginFinalizePage = lazy(() => import('./pages/LoginFinalizePage'));
const LoginFailedPage = lazy(() => import('./pages/LoginFailedPage'));
const LogoutPage = lazy(() => import('./pages/LogoutPage'));
const ForbiddenPage = lazy(() => import('./pages/ForbiddenPage'));
const ServerErrorPage = lazy(() => import('./pages/ServerErrorPage'));


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            minWidth: "100%",
            maxWidth: "100%",
            position: "relative",
            [theme.breakpoints.down("md")]: {
                top: 64,
            },
        },
        main: {
            flexGrow: 1,
            minHeight: "100vh",
            maxWidth: "calc(100% - 350px)",
            minWidth: "calc(100% - 350px)",
            position: "relative",
            [theme.breakpoints.down("md")]: {
                minHeight: "calc(100vh - 64px)",
                maxWidth: "100%",
                minWidth: "100%",
            }
        },
        sidebarContainer: {
            [theme.breakpoints.down("md")]: {
                height: 0,
                width: "100%",
                overflow: "hidden",
                position: "absolute",
                transitionDuration: 500,
                zIndex: 99999
            }
        },
        sidebarContainerOpen: {
            [theme.breakpoints.down("md")]: {
                height: "80vh",
                width: "100vw",
                overflow: "hidden",
                transitionDuration: 500,
            }
        },
    })
);

const App: FC = () => {
    const classes = useStyles();
    const state = useAppState();
    const [mobileNavExpanded, setMobileNavExpanded] = useState<boolean>(false);

    return (
        <Router>
            <Switch>
                <Route path="/login-finalize" exact></Route>
                <Route>
                    <Hidden mdUp>
                        <MobileNavigation isExpanded={mobileNavExpanded} callback={setMobileNavExpanded} />
                    </Hidden>
                </Route>
            </Switch>
            <div className={classes.root}>
                <Switch>
                    <Route>
                        <div className={classnames(classes.sidebarContainer, { [`${classes.sidebarContainerOpen}`]: mobileNavExpanded })}>
                            {/* {mobileNavExpanded ? document.body.style.overflowX = "hidden" : document.body.style.overflowX = "visible"} */}
                            <SidebarNavigation isExpanded={mobileNavExpanded} callback={setMobileNavExpanded} />
                        </div>
                </Route>
                </Switch>
                <div className={classes.main}>
                    <Suspense fallback={<LoadingPage />}>
                        <Switch>
                            {routes.map((route, index) => {
                                let isExact = false;
                                if (route.Exact !== undefined) {
                                    isExact = route.Exact;
                                }

                                if (route.Component === undefined) {
                                    return <Route path={route.Path} exact={isExact} component={NoPageFound} key={route.Path} />
                                }

                                if (route.RequiresAuth) {
                                    return <AuthorizedRoute exact={isExact} path={route.Path} component={route.Component} session={state} key={route.Path} />
                                }

                                return <Route exact={isExact} path={route.Path} component={route.Component} key={route.Path} />
                            })}

                            {authRoutes.map((route, index) => {
                                let isExact = false;
                                if (route.Exact !== undefined) {
                                    isExact = route.Exact;
                                }

                                if (route.Component === undefined) {
                                    return <Route path={route.Path} exact={isExact} component={NoPageFound} key={route.Path} />
                                }

                                if (route.RequiresAuth) {
                                    return <AuthorizedRoute exact={isExact} path={route.Path} component={route.Component} session={state} key={route.Path} />
                                }

                                return <Route exact={isExact} path={route.Path} component={route.Component} key={route.Path} />
                            })}
                            <Route path="/login-finalize" exact component={LoginFinalizePage} />
                            <Route path="/login-failure" exact component={LoginFailedPage} />
                            <Route path="/logout" exact component={LogoutPage} />
                            <Route path="/403" exact component={ForbiddenPage} />
                            <Route path="/404" exact component={NoPageFound} />
                            <Route path="/500" exact component={ServerErrorPage} />
                            <Route component={NoPageFound} />
                        </Switch>
                    </Suspense>
                </div>
            </div>
        </Router>
    );
}

export default App;
