import { createTheme } from '@mui/material/styles';

const BREAKPOINTS = {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
};

const theme = createTheme({
    typography: {
        h2: {
            fontFamily: "Josefin Sans",
        },
        h3: {
            fontFamily: "Montserrat, Verdana, Sans-Serif",
        },
        h4: {
            fontFamily: "Montserrat, Verdana, Sans-Serif",
            fontSize: "16px",
        },
        h5: {
            fontFamily: "Montserrat, Verdana, Sans-Serif",
        },
        subtitle1: {
            fontFamily: "Montserrat, Verdana, Sans-Serif",
        },
        body1: {
            fontSize: "0.875rem",
            lineHeight: "1.43",
            backgroundColor: "#FAFAFA",
            fontFamily: "Montserrat, Verdana, Sans-Serif",
            color: "rgba(0, 0, 0, 0.87)",
        },
        button: {
            color: "rgba(0, 0, 0, 0.87)",
        }
    },
    breakpoints: {
        keys: [ "xs", "sm", "md", "lg", "xl" ],
        values: BREAKPOINTS,
    }
});

export default theme;