import { User } from '../types';
import jwtDecode from "jwt-decode";

type Token = {
    AvatarURL: string;
    Discriminator: string;
    Name: string;
    SessionID: string;
    e: number;
    i: number;
    nbf: number;
};

export type State = {
    LoggedIn: boolean,
    User: User | null,
}

const cache = localStorage.getItem('maplestorygg_session');

export const defaultState: State = {
    LoggedIn: false,
    User: null,
};

const safelyParseCache = (cache: string | null, defaultState: State): State => {
    if (!cache) {
        return defaultState;
    }

    const parsedCache: State = JSON.parse(cache);
    if (JSON.stringify(Object.keys(parsedCache).sort()) !== JSON.stringify(Object.keys(defaultState).sort())) {
        // Error with cache contents: key mismatch from default state
        return defaultState
    }

    if (parsedCache.User === undefined || parsedCache.User === null) {
        return defaultState
    }

    let tkn = jwtDecode<Token>(parsedCache.User.Token)
    let unixNow = Date.now();

    if (Math.floor(unixNow / 1000) >= tkn.e) {
        return defaultState
    }

    if (Math.floor(unixNow / 1000) < tkn.nbf) {
        return defaultState
    }

    return {
        ...parsedCache,
    };
};

export const state: State = safelyParseCache(cache, defaultState);
