import { FC, MouseEvent } from 'react';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { Link as RouterLink } from 'react-router-dom';
import { SxProps } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        flexGrow: 1,
        fontSize: "28px",
    },
    headerNav: {
        background: "#231d34 !important",
        height: 64,
    },
    headerLink: {
        color: "#FFF",
        textDecoration: "none",
    },
    toolbar: {
        height: 64,
    }
}));

interface MobileNavigationSXElements {
    title: SxProps<Theme>;
    headerNav: SxProps<Theme>;
    headerLink: SxProps<Theme>;
    toolbar: SxProps<Theme>;
}

const SXElements: MobileNavigationSXElements = {
    title: {
        flexGrow: 1,
        fontSize: "28px",
    },
    headerNav: {
        background: "#231d34 !important",
        height: "64px",
    },
    headerLink: {
        color: "#FFF",
        textDecoration: "none",
    },
    toolbar: {
        height: "64px",
    }
}

interface MobileNavigationProps {
    callback: (mobileNavState: boolean) => void;
    isExpanded: boolean;
}

const MobileNavigation: FC<MobileNavigationProps> = (props: MobileNavigationProps) => {
    const classes = useStyles();

    const navToggle = (evt: MouseEvent) => {
        evt.preventDefault();

        if (props.isExpanded) {
            props.callback(false);
        } else {
            props.callback(true);
        }
    }

    return (
        <AppBar sx={SXElements.headerNav} position="fixed">
            <Toolbar sx={SXElements.toolbar}>
                <Typography variant="h2" sx={SXElements.title}>
                    <RouterLink className={classes.headerLink} to="/">
                        MapleStory.GG
                    </RouterLink>
                </Typography>
                <IconButton edge={false} onClick={navToggle} color="inherit" aria-label="menu">
                    <FontAwesomeIcon icon={faBars} />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}

export default MobileNavigation;