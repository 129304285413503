import { Overmind, rehydrate } from 'overmind';
import jwt_decode from "jwt-decode";
import { User, UserJWT } from "../types"
import { Dispatch, SetStateAction } from 'react';
import { Context } from './';

export const onInitializeOvermind = async (context: Context, instance: Overmind<Context>) => {
    instance.addMutationListener((mutation) => {
        if (mutation.path.indexOf('User') === 0) {
            localStorage.setItem('maplestorygg_session', JSON.stringify(context.state))
        }
    })
}


export const updateUserToken = (context: Context, userToken: string) => {
    const token = jwt_decode<UserJWT>(userToken)

    let user: User = {
        Token: userToken,
        Name: token.Name,
        Discriminator: token.Discriminator,
        AvatarURL: token.AvatarURL,
    }

    rehydrate(context.state, { LoggedIn: true, User: user })
}

export const logout = (context: Context, callback: Dispatch<SetStateAction<boolean>> | undefined) => {
    rehydrate(context.state, { LoggedIn: false, User: null })
    context.effects.API.logout(context.state).then(() => {
        if (callback !== undefined) {
            callback(true)
        }
    })
}

export const verifySession = (context: Context, message: Object) => {
    let objectKeys = Object.keys(message);

    if (objectKeys.includes("HTTPError")) {
        let objMap: Map<string, any> = new Map<string, any>(Object.entries(message));
        if (objMap.get("HTTPError") === 403 && objectKeys.includes("ErrorMessage") && (objMap.get("ErrorMessage") as string).toLowerCase() === "invalid authorization token") {
            rehydrate(context.state, { LoggedIn: false, User: null })
        }
    }
}
