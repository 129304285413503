import { FC, Fragment, MouseEvent, PropsWithChildren } from 'react';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import CharacterSearchForm from './CharacterSearchForm';
import SidebarHeader from './SidebarHeader';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles';
import { routes, authRoutes } from '../routes';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { useAppState } from '../overmind';
import discordlogo from '../images/discord-logo-white.svg';
import { SxProps } from '@mui/system';
import { useTheme, useMediaQuery } from '@mui/material';
import theme from '../theme';
import { SystemStyleObject } from '@mui/system';
import { ResponsiveStyleValue } from '@mui/system/styleFunctionSx';
import { Property } from 'csstype';

const drawerWidth = "350px";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            width: `${drawerWidth} !important`,
            flexShrink: 0,
            [theme.breakpoints.down('md')]: {
                width: "100vw !important",
            },
        },
        drawerOpen: {
            [theme.breakpoints.down('md')]: {
                width: "100vw",
                height: "100%",
            },
        },
        drawerPaper: {
            width: drawerWidth,
            // background: "#001B2E",
            background: "#231d34 !important",
            [theme.breakpoints.down('md')]: {
                position: "absolute !important",
                width: "100vw !important",
            },
        },
        drawerPaperOpen: {
            [theme.breakpoints.down('md')]: {
                width: "100vw !important",
            },
        },
        navItem: {
            textAlign: "right",
        },
        navItemText: {
            color: "#EEF !important",
            fontFamily: "Montserrat, Verdana, Sans-Serif !important",
            fontWeight: 300,
            fontSize: "1.2rem !important",
            textDecoration: "none !important",
            backgroundColor: "rgba(0,0,0,0) !important",
        },
        navItemLink: {
            display: "block",
            width: "100%",
            height: "100%",
            textDecoration: "none !important",
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            transitionDuration: "0.3s",
            color: "#EEF !important",
            '&:hover': {
                paddingRight: "10% !important",
                color: "#EEF !important",
            },
            '&:visited': {
                color: "#EEF !important",
            },
            '&:active': {
                color: "#EEF !important",
            },
        },
        navItemLoginLink: {
            display: "flex",
        },
        navItemLoginDiscordLink: {
            backgroundColor: "#7289DA  !important",
            padding: "10px 30px 7px 25px !important",
            margin: "0 10px 0 10px !important",
            borderRadius: 5,
        },
        divider: {
            backgroundColor: "rgba(255,255,255,0.1) !important",
        },
        loggedInItem: {
            textAlign: 'right',
        },
        loggedInItemText: {
            color: "#EEF !important",
            fontFamily: "Montserrat, Verdana, Sans-Serif !important",
            fontWeight: 300,
        },
        loggedInLogoutText: {
            color: "#CCD",
            fontFamily: "Montserrat, Verdana, Sans-Serif !important",
        },
        loggedInLogoutLink: {
            color: "#CCD",
            textDecoration: "none !important",
            "&:hover": {
                textDecoration: "underline !important",
            }
        },
        sidebarSearchForm: {
            padding: "3px 10px !important",
        }
    }),
);

const navItemListTextSX: SxProps<Theme> = {
    fontWeight: 300,
    fontSize: "1.2rem",
    textDecoration: "none",
    backgroundColor: "rgba(0,0,0,0)",
    textAlign: "right",
}

const navItemSpanTextSX: SxProps<Theme> = {
    color: "#EEF",
    fontFamily: "Montserrat, Verdana, Sans-Serif",
    fontWeight: 300,
    fontSize: "1.2rem",
    textDecoration: "none",
    backgroundColor: "rgba(0,0,0,0)",
    textAlign: "right",
}

interface LoggedInItemsProps {
    callback: (mobileNavState: boolean) => void;
}

export const LoggedInItems: FC<LoggedInItemsProps> = (props: LoggedInItemsProps) => {
    const classes = useStyles();
    const state = useAppState();
    let location = useLocation();

    const handleLinkClick = (evt: MouseEvent) => {
        props.callback(false);
    }

    if (state.LoggedIn === false) {
        let returnPath = `${process.env.REACT_APP_API_HOST}/login`;
        if (location.pathname.toLowerCase() !== "/login" && location.pathname.toLowerCase() !== "/login-failure") {
            returnPath = `${returnPath}?r=${location.pathname}`
        }

        if (location.pathname.toLowerCase() === "/login-failure") {
            returnPath = `${returnPath}${location.search}`
        }
        if (returnPath === "") { }

        return (<Fragment>
            <List>
                <ListItem disableGutters={true} dense={true} className={classes.loggedInItem}>
                    <Link className={`${classes.navItemLink} ${classes.navItemLoginLink} ${classes.navItemLoginDiscordLink}`} component={RouterLink} onClick={(e: MouseEvent<HTMLAnchorElement>) => { e.preventDefault(); window.location.href = returnPath }} to={{ pathname: returnPath }}>
                        <ListItemAvatar>
                            <Avatar src={discordlogo} />
                        </ListItemAvatar>
                        <ListItemText sx={navItemListTextSX} disableTypography={true} classes={{ primary: classes.loggedInItemText, secondary: classes.loggedInLogoutText }}>
                            <Typography component="span" sx={navItemSpanTextSX}>Login With Discord</Typography>
                        </ListItemText>
                    </Link>
                </ListItem>
            </List>
            <Divider className={classes.divider} />
        </Fragment>);
    }
    return (
        <Fragment>
            <List>
                <ListItem className={classes.loggedInItem}>
                    <ListItemAvatar>
                        <Avatar src="https://cdn.discordapp.com/avatars/131215486837981184/8fc1f6cc28c9e9318ed54d198de88416.webp?size=128" />
                    </ListItemAvatar>
                    <ListItemText
                        className={classes.loggedInItemText}
                        primary="Logged in as Arteia"
                        secondary={<RouterLink className={classes.loggedInLogoutLink} to="/logout">Logout</RouterLink>}
                        classes={{ primary: classes.loggedInItemText, secondary: classes.loggedInLogoutText }}>
                    </ListItemText>
                </ListItem>
            </List>
            <Divider className={classes.divider} />
            <List>
                {authRoutes.map((route, index) => {
                    if (route.DisplayInNav === undefined || route.DisplayInNav !== false) {
                        return (<ListItem disableGutters={true} dense={true} className={classes.navItem} key={`authRoute_${index}`}>
                            <Link onClick={handleLinkClick} className={classes.navItemLink} component={RouterLink} to={route.Path}>
                                <ListItemText className={classes.navItemText} disableTypography={true}>
                                    <Typography component="span" sx={{ textAlign: "right" }} className={classes.navItemText}>{route.Title}</Typography>
                                </ListItemText>
                            </Link>
                        </ListItem>)
                    } else {
                        return ""
                    }
                })}
            </List>
            <Divider className={classes.divider} />
        </Fragment>
    );
}

interface SidebarNavigationProps {
    callback: (mobileNavState: boolean) => void;
    isExpanded: boolean;
}

const SidebarNavigation: FC<PropsWithChildren<SidebarNavigationProps>> = (props: PropsWithChildren<SidebarNavigationProps>) => {
    const classes = useStyles();
    const tme = useTheme<typeof theme>();
    const isMobile = useMediaQuery(tme.breakpoints.down('md'))
    let location = useLocation();

    const handleLinkClick = (evt: MouseEvent) => {
        props.callback(false);
    }

    const SearchForm = (): JSX.Element => {
        if (location.pathname !== "/") {
            return <div className={classes.sidebarSearchForm}><CharacterSearchForm isSidebar={true} /></div>
        }

        return <Fragment></Fragment>
    }

    interface DrawerStyles {
        "& .MuiDrawer-paper"?: SxProps<Theme>;
        width?: number | string;
        height?: number | string;
        flexShrink?: number;
    }

    interface DrawerPaperStyles {
        width?: number | string;
        position?: SystemStyleObject<Theme> | ResponsiveStyleValue<Property.Position | (Property.Position | undefined)[] | undefined>;
        background?: SystemStyleObject<Theme> | ResponsiveStyleValue<Property.Background<string | number> | (Property.Background<string | number> | undefined)[] | undefined>;
    }

    const GetExpandedDrawerStyles = (): SxProps<Theme> => {
        let retVal: DrawerStyles = {
            "& .MuiDrawer-paper": GetExpandedDrawerPaperStyles(),
        };
        retVal.width = drawerWidth;
        retVal.flexShrink = 0;
        if(isMobile) {
            retVal.width = "100vw !important";
        }

        if (props.isExpanded) {
            if(isMobile) {
                retVal.width = "100vw !important";
                retVal.height = "100%";
            }
        }

        return retVal
    }

    const GetExpandedDrawerPaperStyles = (): SxProps<Theme> => {
        let retVal: DrawerPaperStyles = {};
        retVal.width = drawerWidth;
        retVal.background = "#231d34";
        if(isMobile) {
            retVal.width = "100vw !important";
            retVal.position = "absolute";
        }

        if (props.isExpanded) {
            if(isMobile) {
                retVal.width = "100vw !important";
            }
        }

        return retVal
    }

    return (
        <Drawer
            variant="permanent"
            anchor="left"
            sx={GetExpandedDrawerStyles()}
            >
            <Hidden mdDown>
                <SidebarHeader />
            </Hidden>
            {SearchForm()}
            {/* {LoggedInItems(props)} */}
            <List>
                {routes.map((route, index) => {
                    if (route.DisplayInNav === undefined || route.DisplayInNav !== false) {
                        return (<ListItem disableGutters={true} dense={true} className={classes.navItem} key={`route_${index}`}>
                            <Link onClick={handleLinkClick} className={classes.navItemLink} component={RouterLink} to={route.Path}>
                                <ListItemText sx={navItemListTextSX} disableTypography={true}>
                                    <Typography sx={navItemSpanTextSX} component="span">{route.Title}</Typography>
                                </ListItemText>
                            </Link>
                        </ListItem>)
                    } else {
                        return ""
                    }
                })}
                <ListItem disableGutters={true} dense={true} className={classes.navItem}>
                    <Link className={classes.navItemLink} component={RouterLink} to="/" onClick={(e: MouseEvent<HTMLElement>) => { e.preventDefault(); props.callback(false); window.location.href = "https://discord.gg/tJ8aCUP" }}>
                        <ListItemText sx={navItemListTextSX} disableTypography={true}>
                            <Typography sx={navItemSpanTextSX} component="span">Join the Discord Server</Typography>
                        </ListItemText>
                    </Link>
                </ListItem>
                <ListItem disableGutters={true} dense={true} className={classes.navItem}>
                    <Link className={classes.navItemLink} component={RouterLink} to="/" onClick={(e: MouseEvent<HTMLElement>) => { e.preventDefault(); props.callback(false); window.location.href = "https://discordapp.com/api/oauth2/authorize?&client_id=521589271526768660&scope=bot&permissions=8" }}>
                        <ListItemText sx={navItemListTextSX} disableTypography={true}>
                            <Typography sx={navItemSpanTextSX} component="span">Discord Bot Invite</Typography>
                        </ListItemText>
                    </Link>
                </ListItem>
            </List>
        </Drawer>
    );
};

export default SidebarNavigation;