import { FC, SyntheticEvent } from 'react';
import Typography from '@mui/material/Typography';
import RockSpiritsWebp from '../images/loading-rockspirits.webp';
import RockSpiritsImage from '../images/loading-rockspirits.png';
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            minHeight: "100%",
            maxHeight: "100%",
            background: "#231d34",
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
        },
        centered: {
            width: "100%",
            textAlign: "center",
        },
        img: {
            maxWidth: "100%",
        },
        loadingText: {
            color: "#FFF",
            fontSize: "32px",
        },
        imgDiv: {
            height: 320,
            width: 'auto',
        },
    })
);

const LoadingPage: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.centered}>
                <div className={classes.imgDiv}>
                    <img width="auto" height={320} className={classes.img} alt="Three rock spirits blocking loading" src={RockSpiritsWebp} onError={(evt: SyntheticEvent<HTMLImageElement>) => { evt.currentTarget.onerror = null; evt.currentTarget.src = RockSpiritsImage }} />
                </div>
                <Typography className={classes.loadingText} variant="h2">Loading...</Typography>
            </div>
        </div>
    )
}

export default LoadingPage;
